<!-- 考试大类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import UserHeader from "../user-header.vue";
  import YzSearch from "@/components/form/yzSearch.vue";

  import {
    getUser,
    deleteUser,
    lockUser,
    updatePassword,
    flushCache,
    clearOut
  } from "@/api/admin/user.js";
  import {
    loginRegisterAccount
  } from "@/api/admin/user";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      UserHeader,
      YzSearch,
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "用户管理",
            active: true,
          },
        ],
        checked: true,
        // defaultvalue: 3,
        // currentpage: 3,
        tabList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 1,
        },
        showPromission: false,
        sfType: {
          1: "居民身份证",
          5: "部队离退休证",
          9: "境外永久居住证",
          2: "军官证",
          6: "香港特区护照/身份证明",
          A: "护照",
          3: "士兵证",
          7: "澳门特区护照/身份证明",
          B: "户口薄",
          4: "文职干部证",
          8: "台湾居民来往大陆通行证",
          Z: "其他",
        },
        form: {
          uid: "",
          mm: "",
        },
        search: [],
        showCol:false
      };
    },
    methods: {
      getUserList() {
        getUser(this.pageData).then((res) => {
          if (res.status) {
            this.tabList = res.data;
            this.pageData.total = res.total;
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页
        this.getUserList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getUserList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getUserList();
      },
      // 删除
      deleteItem(uid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的用户数据, 是否继续?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            deleteUser(uid).then((res) => {
              if (res.status) {
                this.$message({
                  type: "success",
                  message: "数据删除成功!",
                });
              }
              this.getUserList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      // 是否锁定
      sfsdChange(uid, sfsd) {
        lockUser(uid, sfsd).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          }
        });
      },
      // updatePassword
      updatePassword(uid) {
        this.showPromission = true;
        this.form = {
          uid: uid,
          mm: "",
        };
      },
      startUpdate(uid, mm) {
        updatePassword(uid, mm).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "更新成功",
            });
            this.showPromission = false;
          }
        });
      },
      loginRegisterAccount(sfzjh) {
        loginRegisterAccount({
          sfzjh: sfzjh
        }).then(res => {
          if (res.status) {
            // 获取到管理员加密token
            window.open(`${this.REGISTER_URL}/casLogin?t=${res.data.k}&s=${res.data.v}`)
          }
        })
      },
      clearCache(sfzjh,xm){
        this.$confirm(`此操作将清除${xm}的缓存, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
                  flushCache(sfzjh).then((res) => {
                    if (res.status) {
                      this.$message({
                        type: "success",
                        message: "缓存清除成功!",
                      });
                    }
                    this.getUserList();
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消清除",
                  });
                });

      },
      /** 清退按钮操作 */
      handleOut() {
        this.$confirm(`此操作将清退所有数据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          clearOut().then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "清退成功!",
              });
              this.getUserList();
            }
          });
        }).catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消清退",
                  });
                });
      }
    },
    mounted() {
      // this.getUserList();
      this.search = [{
        title: "姓名/证件号/手机号/邮箱",
        bound: "keyword",
        type: "input",
      }, ];
      if (this.userInfo && this.userInfo.roleNum == 10){
        this.showCol=true
      }else{
        this.showCol=false
      }
    },
  };
</script>

<template>
  <Layout>
    <UserHeader :items="items" >
      <template> <el-button type="danger" size="mini" class="h30" @click="handleOut"><i
              class="iconfont icon-come-l  mr-1"></i>清退</el-button></template>

    </UserHeader>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick()"></yz-search>
                <!--                <b-button-->
                <!--                  v-b-modal.more-condition-->
                <!--                  variant="outline-info"-->
                <!--                  class="flexList mr-2 condition"-->
                <!--                  ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i-->
                <!--                  >添加用户</b-button-->
                <!--                >-->
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%">序号</th>
                    <th style="width: 5%">姓名</th>
                    <th style="width: 11%">证件号码</th>
                    <th style="width: 6%">证件类型</th>
                    <th style="min-width: 5%">电子邮箱</th>
                    <th style="width: 5%">是否锁定</th>
                    <th style="width: 7%">联系电话</th>
                    <th style="width: 10%">注册时间</th>
                    <th style="width: 10%">最后登录时间</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 5%;">快速登陆</th>
                    <th style="width:6%;" v-if="showCol">清除缓存</th>
                    <th style="width: 3%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in tabList" :key="index">
                    <td scope="row">{{ (pageData.pageNum - 1) * pageData.pageSize +  index + 1 }}</td>
                    <td>{{ obj.xm }}</td>
                    <td>
                      <a href="javascript:;" class="blue-font"
                        @click="$yzUserInfo().show(obj.sfzjhEnc)">{{ obj.sfzjh }}</a>
                    </td>
                    <td>{{ sfType[obj.sfzjlxm] }}</td>
                    <td>{{ obj.dzxx }}</td>
                    <td>
                      <b-form-checkbox @change="sfsdChange(obj.uid, obj.sfsd)" v-model="obj.sfsd" switch
                        class="switch-check">
                      </b-form-checkbox>
                    </td>
                    <td>{{ obj.yddh }}</td>
                    <td>{{ obj.createTime }}</td>
                    <td>{{ obj.zhdlsj }}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td>
                      <a href="javascript:;" class="blue-font" @click="loginRegisterAccount(obj.sfzjhEnc)">登录</a>
                    </td>
                    <td v-if="showCol"><button  type="button" style="padding: 0.47rem 0.3rem" class="btn btn-info h30" @click="clearCache(obj.sfzjhEnc,obj.xm)">清除缓存 </button></td>
                    <td class="tab-icon">
                      <a :href="'/admin/personalData?sfzjh=' + obj.sfzjhEnc" target="_blank"><i
                          class="iconfont icon-edit-two text-dark align-middle mr-1"></i></a><i
                        class="iconfont icon-key align-middle mr-1" @click="updatePassword(obj.uid)"></i><i
                        class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.uid,obj.xm)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗开始 -->
    <!--添加机构用户-->
    <b-modal v-model="showPromission" centered title="修改密码" size="sm" title-class="font-18" hide-footer>
      <div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0">密码：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="text" v-model="form.mm" placeholder="新密码" class="form-control w-70 h30" />
            <span class="grey-time ml-2">(新密码)</span>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <button type="button" class="btn btn-info h30 w-md mr-3" @click="startUpdate(form.uid, form.mm)">
          确定修改密码
        </button>
        <button type="button" class="btn btn-secondary h30 w-md" @click="showPromission = false">
          取消
        </button>
      </div>
    </b-modal>

    <!-- 弹窗结束-->
  </Layout>
</template>
