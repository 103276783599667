import { getRequest, patchRequest, deleteRequest, postRequest} from "@/libs/axios";

// 查询考生用户分页
export const getUser = (params) => {
  return getRequest("/yethan/UserRegister/listPage", params);
};

// 锁定解锁考生
export const lockUser = (uid, sfsd) => {
  return patchRequest(`/yethan/UserRegister/${uid}/sfsd`, { sfsd: sfsd });
};
// 删除考生
export const deleteUser = (uid) => {
  return deleteRequest(`/yethan/UserRegister/${uid}`);
};

// 更新考生密码
export const updatePassword = (uid, mm) => {
  return patchRequest(`/yethan/UserRegister/${uid}/password`, { mm: mm });
};


// 考生图片分页
export const userPhotoPage = params => {
  return getRequest(`/yethan/userInfo/photo/listPage`, params);
};

// 考生教育分页
export const userEduPage = params => {
  return getRequest(`/yethan/userInfo/edu/listPage`, params);
};


// 管理员快速登录账号
export function loginRegisterAccount(params={}) {
  return getRequest("/yethan/UserRegister/loginRegisterAccount", params);
}

//管理员清除缓存
export function flushCache(sfzjh) {
  return getRequest(`/yethan/UserRegister/flushCache?sfzjh=${sfzjh}`);
}
//管理员清退
export function clearOut() {
  return getRequest(`/yethan/UserRegister/flushRegisterCache`);
}
